import React, { Component } from 'react';
import './Statistics.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStat } from '../../../actions/statisticActions';
import Spinner from '../../common/Spinner/Spinner';
import StatItem from './StatItem';


class Statistics extends Component {
  componentDidMount() {
    this.props.getStat();
  }

  render() {
    const { stat, fetching } = this.props;
    let statItems = null;

    if (stat.length > 0) {
      statItems = stat.map((item, index) => <StatItem key={index} name={item.name} value={item.value} icon={item.icon} index={index} />);
    }

    if (fetching) {
      return <Spinner style={{ marginTop: '295px', marginBottom: '295px' }} />;
    } else if (stat.length < 1) {
      return null;
    } else {
      return (
        <section className="statistics">
          {statItems}
        </section>
      );
    }
  }
}


Statistics.propTypes = {
  stat: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  getStat: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
  stat: state.statistics.statistics,
  fetching: state.statistics.fetching
});


export default connect(mapStateToProps, { getStat })(Statistics);
