import React, { Component } from 'react';
import './Posts.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecentPosts } from '../../../actions/postActions';
import classnames from 'classnames';
import Reveal from 'react-reveal/Reveal';
import Spinner from '../../common/Spinner/Spinner';
import PostItem from './PostItem';
import ScrollIndicator from '../../common/ScrollIndicator/ScrollIndicator';


class Posts extends Component {
  constructor() {
    super();

    this.images = [
      React.createRef(),
      React.createRef(),
      React.createRef()
    ];

    this.state = {
      posts: []
    };
  }

  componentDidMount() {
    this.props.getRecentPosts();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.posts) return { posts: nextProps.posts };
    return null;
  }

  onMouseEnterHandler = (index) => {
    let lastIndex = (index > 0) ? index - 1 : this.state.posts.length - 1;

    for (const image of this.images) {
      image.current.classList.remove('new__image--first', 'new__image--last');
    }

    this.images[index].current.classList.add('new__image--first');
    this.images[lastIndex].current.classList.add('new__image--last');

  }

  render() {
    const { posts } = this.state;
    const { fetching } = this.props;
    let newItems = null;
    let newImages = null;

    if (posts.length > 0) {
      newItems = posts.map((item, index) => (
        <PostItem
          key={item.id}
          link={`/hirek/${item.slug}`}
          title={item.title}
          excerpt={item.excerpt}
          index={index}
          onMouseEnter={this.onMouseEnterHandler.bind(this, index)}
        />
      ));

      newImages = posts.map((item, index) => (
        <img
          key={item.id}
          ref={this.images[index]}
          className={classnames('new__image', {
            'new__image--first': index === 0,
            'new__image--last': index === posts.length - 1
          })}
          src={item.image}
          alt={item.slug}
        />
      ));
    }

    if (fetching) {
      return (
        <div className="news-wrapper">
          <Spinner style={{ marginTop: '300px', marginBottom: '300px' }} />
        </div>
      );
    } else if (posts.length < 3) {
      return (
        <h5 className="text-center my-5">
          Nem található elegendő bejegyzés a megfelelő megjelenítéshez
        </h5>
      );
    } else {
      return (
        <div className="news-wrapper" id="hirek">
          <section className="news container">
            <ScrollIndicator to="/#hirek" />
            <div className="row">

              <Reveal effect="grow-in-left">
                <div className="col-6 images d-none d-lg-block">

                  {newImages}

                </div>
              </Reveal>

              <Reveal effect="grow-in-right">
                <div className="col-12 col-lg-6">
                  <h1 className="section__name">
                    <strong>Hírek</strong>, információk
                  </h1>

                  {newItems}

                  <Link to="/hirek" className="my-btn my-btn--light btn-more">
                    További hírek
                  </Link>
                </div>
              </Reveal>
            </div>
          </section>
        </div>
      );
    }
  }
}


Posts.propTypes = {
  posts: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  getRecentPosts: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
  posts: state.posts.recent,
  fetching: state.posts.fetching
});


export default connect(mapStateToProps, { getRecentPosts })(Posts);
