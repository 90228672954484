const initialState = {
  nav: {
    open: false
  },
  search: {
    open: false
  }
};


export default (state = initialState, action) => {
  switch (action.type) {

    case 'SET_NAVBAR_STATE':
      return {
        ...state,
        nav: {
          open: !state.nav.open
        }
      };

    case 'SET_SEARCHBAR_STATE':
      return {
        ...state,
        search: {
          open: !state.search.open
        }
      };

    default:
      return state;

  }
};
