import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


const NewItem = (props) => {
  return (
    <Link to={props.link} className="new" onMouseEnter={props.onMouseEnter}>
      <div className="new__title">
        <div className="number">{`0${props.index + 1}`}</div>
        <div className="bar"></div>
        {props.title}
      </div>
      <div className="new__excerpt">
        { ReactHtmlParser(props.excerpt.replace(' [&hellip;]', '...')) }
      </div>
    </Link>
  )
}


NewItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func.isRequired
};


export default NewItem;
