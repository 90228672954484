import React from 'react';
import './FormInput.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const FormTextArea = ({ name, label, value, onChange, error, disabled, style }) => {
  return (
    <div
      className={classnames('form-input-group', {
        'form-input-group--focus': value.length > 0,
        'is-invalid': error
      })}
      style={ style }
    >
      <textarea
        disabled={disabled}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        style={{ height: '100%' }}
      />
      <span className="bar"></span>
      <label htmlFor={name}>{label}</label>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};


FormTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object
};


FormTextArea.defaultProps = {
  disabled: false
};

export default FormTextArea;
