import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';


const StatItem = (props) => {
  return (
    <div className="stat-item">
      <div className="stat-item__icon-wrapper">
        <img className="stat-item__icon" src={props.icon} alt={props.name} />
      </div>
      <div className="stat-item__value">{ReactHtmlParser(props.value)}</div>
      <div className="stat-item__description">{props.name}</div>
    </div>
  );
};


StatItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};


export default StatItem;
