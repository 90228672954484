const initialState = {
  reviews: [],
  fetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {

  case 'GET_REVIEWS':
    return {
      ...state,
      reviews: action.payload,
      fetching: false
    }

  case 'REVIEWS_LOADING':
    return {
      ...state,
      fetching: true
    }

  default:
    return state
  }
}
