import React from 'react';
import './Home.scss';

import Categories from './Categories/Categories';
import Posts from './Posts/Posts';
import Partners from './Partners/Partners';
import Reviews from './Reviews/Reviews';
import Statistics from './Statistics/Statistics';
import Contact from './Contact/Contact';
import Form from '../Contact/Form/Form';


const Home = () => {
  return (
    <div className="home fade-in">
      <Categories />
      <Posts />
      <Partners />
      <Reviews />
      <Statistics />

      <div className="lines">
        <div className="line line-1"></div>
        <div className="line line-2"></div>
        <div className="line line-3"></div>
        <div className="line line-4"></div>
        <div className="line line-5"></div>
        <div className="line line-6"></div>
      </div>

    </div>
  );
};


export default Home;
