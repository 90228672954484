import React from 'react';
import './FormInput.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const FormInput = ({ type, name, label, value, onChange, error, disabled }) => {
  return (
    <div className={classnames('form-input-group', {
      'form-input-group--focus': value.length > 0,
      'is-invalid': error
    })}>
      <input
        type={type}
        disabled={disabled}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
      />
      <span className="bar"></span>
      <label htmlFor={name}>{label}</label>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};


FormInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool
};


FormInput.defaultProps = {
  type: 'text',
  disabled: false
};

export default FormInput;
