import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import Header from './components/layout/Header/Header';
import SideNav from './components/layout/Sidenav/Sidenav';
import SearchBar from './components/layout/SearchBar/SearchBar';
import Footer from './components/layout/Footer/Footer';
import Home from './components/Home/Home';
import Info from './components/Info/Info';
import Contact from './components/Contact/Contact';
import Posts from './components/Posts/Posts';
import Post from './components/Posts/Post';
import Products from './components/Products/Products';
import Product from './components/Products/Product';
import Categories from './components/Products/Categories';
import MainCategories from './components/Products/MainCategories';
import ProductSearch from './components/Search/Products';
import RouterUpdate from './components/common/RouterUpdate';


const App = () => {
  return (
    <Provider store={ store }>
      <Router>
        <div className="App">
          <RouterUpdate />
          <Header />
          <SideNav />
          <SearchBar />

          <div className="content">
            <Switch>
              <Route exact path="/kapcsolat" component={ Contact } />
              <Route exact path="/hirek" component={ Posts } />
              <Route exact path="/informacio/:slug" component={ Info } />
              <Route exact path="/hirek/:slug" component={ Post } />
              <Route exact path="/termekek/:slug" component={ Products } />
              <Route exact path="/termek/:slug" component={ Product } />
              <Route exact path="/kategoriak/" component={ MainCategories } />
              <Route exact path="/kategoriak/:slug" component={ Categories } />
              <Route exact path="/kereses/termek/:searchString" component={ ProductSearch } />
              <Route exact path="*" component={ Home } />
            </Switch>
          </div>

          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
