import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Form.scss';
import { Link } from 'react-router-dom';
import validateMessageInput from '../../../validation/contact';
import LoaderButton from '../../common/LoaderButton/LoaderButton';
import FormInput from './FormInput';
import FormTextArea from './FormTextArea';
import FormCheckBox from './FormCheckBox';
import Reveal from 'react-reveal/Reveal';


class Form extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      company: '',
      email: '',
      tel: '',
      title: '',
      message: '',
      terms: false,

      sending: false,
      success: false,
      failed: false,

      errors: {}
    };
  }

  componentDidMount = () => {
    if (this.props.title) this.setState({ title: this.props.title });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCheck = (e) => {
    this.setState({ terms: e.target.checked });
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { isValid, errors } = validateMessageInput(this.state);
    if (!isValid) return this.setState({ errors });

    this.setState({ sending: true });

    const formData = `name=${this.state.name}&company=${this.state.company}&email=${this.state.email}&tel=${this.state.tel}&title=${this.state.title}&message=${this.state.message}&terms=${this.state.terms}`;

    fetch(`${window.location.origin}/mail/contact.php`, {
      method: 'POST',
      body: formData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(res => {
      if (res.status < 200 || res.status >= 300) {
        this.setState({ failed: true, sending: false });
        setTimeout(() => this.setState({ failed: false }), 3000);
        return;
      }

      // Reset state variables
      this.setState({ sending: false, success: true });
      setTimeout(() => {
        this.setState({
          name: '',
          company: '',
          email: '',
          tel: '',
          title: '',
          message: '',
          terms: false,
          errors: {},
          success: false
        });
      }, 3000);
    })
    .catch(err => {
      this.setState({ failed: true });
      this.captcha.reset();
      setTimeout(() => this.setState({ sending: false, failed: false }), 3000);
    });
  }

  render() {
    return (
      <Reveal effect="grow-in-bottom">
        <div className="contact-form-wrapper">
          <h1 className="text-center">Kérdése van? <strong>Keressen minket bizalommal!</strong></h1>
          <form noValidate onSubmit={this.onSubmit} className="contact-form d-flex flex-column">
            <div className="row justify-content-center justify-content-lg-between">
              <div className="col-12 col-md-10 col-lg-6 col-xl-5">
                <FormInput
                  type="text"
                  name="name"
                  label="Név"
                  value={this.state.name}
                  onChange={this.onChange}
                  disabled={this.state.sending}
                  error={this.state.errors.name}
                />

                <FormInput
                  type="text"
                  name="company"
                  label="Cégnév"
                  value={this.state.company}
                  onChange={this.onChange}
                  disabled={this.state.sending}
                  error={this.state.errors.company}
                />

                <FormInput
                  type="email"
                  name="email"
                  label="Email cím"
                  value={this.state.email}
                  onChange={this.onChange}
                  disabled={this.state.sending}
                  error={this.state.errors.email}
                />

                <FormInput
                  type="tel"
                  name="tel"
                  label="Telefonszám"
                  value={this.state.tel}
                  onChange={this.onChange}
                  disabled={this.state.sending}
                  error={this.state.errors.tel}
                />
              </div>

              <div className="col-12 col-md-10 col-lg-6 col-xl-5">
                <FormInput
                  type="text"
                  name="title"
                  label="Tárgy"
                  value={this.state.title}
                  onChange={this.onChange}
                  disabled={this.state.sending}
                  error={this.state.errors.title}
                />

                <FormTextArea
                  name="message"
                  label="Üzenet"
                  value={this.state.message}
                  onChange={this.onChange}
                  disabled={this.state.sending}
                  style={{ height: 'calc(100% - 97.4px)' }}
                  error={this.state.errors.message}
                />

              </div>
            </div>

            <div className="row justify-content-center justify-content-lg-between">
              <div className="col-12 col-sm-10 col-lg-7  mt-3 mt-md-0">
                <FormCheckBox
                  name="terms"
                  checked={this.state.terms}
                  onChange={this.onCheck}
                  disabled={this.state.sending}
                  error={this.state.errors.terms}
                >
                  Elfogadom a <Link to="/informacio/adatkezelesi-iranyelvek">Gumex Kft. adatkezelési irányelveit</Link>
                </FormCheckBox>
              </div>
              <div className="col-12 col-sm-10 col-lg-5 mt-3 mt-md-0">
                <LoaderButton
                  value="Küldés"
                  load={this.state.sending}
                  check={this.state.success}
                  fail={this.state.failed}
                />
              </div>
            </div>
          </form>
        </div>
      </Reveal>
    );
  }
}


Form.propTypes = {
  title: PropTypes.string
};


export default Form;
