const initialState = {
  logos: [],
  fetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {

  case 'GET_PARTNERS':
    return {
      ...state,
      logos: action.payload,
      fetching: false
    }

  case 'PARTNERS_LOADING':
    return {
      ...state,
      fetching: true
    }

  default:
    return state
  }
}
