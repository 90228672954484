import React, { Component } from 'react';
import './Products.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMainCategories } from '../../actions/productActions';
import ReactHtmlParser from 'react-html-parser';
import Spinner from '../common/Spinner/Spinner';


class MainCategories extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    getMainCategories: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.getMainCategories();
  }

  render() {
    const { categories } = this.props;
    let categoryItems = null;

    if (categories.length > 0) {
      categoryItems = categories.map((category, index) => (
        <Link to={`/kategoriak/${category.slug}`} key={index} className="product-item">
          <img src={category.image} alt={category.slug} />
          <div className="title">
            {ReactHtmlParser(category.name)}
          </div>
          <div className="product-item-button">
            <i className="material-icons">keyboard_arrow_right</i>
          </div>
        </Link>
      ));
    }

    if (categories.length === 0) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else {
      return (
        <div className="products container fade-in">
          {categoryItems}
        </div>
      );
    }
  }
}


const mapStateToProps = (state) => ({
  categories: state.products.categories.main,
  fetching: state.products.fetching
});

export default connect(mapStateToProps, { getMainCategories })(MainCategories);