import React from 'react';
import './ScollIndicator.scss';
import PropTypes from 'prop-types';
import { HashLink } from "react-router-hash-link";


const ScrollIndicator = ({ to }) => (
  <HashLink smooth to={to} className="scroll-indicator-wrapper">
    <div className="scroll-indicator">
      <div className="scroll-wheel"></div>
    </div>
  </HashLink>
);


ScrollIndicator.propTypes = {
  to: PropTypes.string
};

export default ScrollIndicator;
