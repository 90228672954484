export const setNavBarState = () => {
  document.body.classList.toggle('disable-scroll');
  return {
    type: 'SET_NAVBAR_STATE'
  };
};

export const setSearchBarState = () => {
  return {
    type: 'SET_SEARCHBAR_STATE'
  };
};
