import config from '../config';


// Get reviews
export const getReviews = () => async (dispatch) => {
  dispatch(setReviewsLoading());

  const res = await fetch(`${config.fetchURL}/visszajelzesek`);
  const json = await res.json();

  const reviews = json.map(item => ({
    name: item.title.rendered,
    text: item.acf.leiras
  }));

  dispatch({
    type: 'GET_REVIEWS',
    payload: reviews
  });

};


// Reviews loading
export const setReviewsLoading = () => {
  return {
    type: 'REVIEWS_LOADING'
  };
};
