const initialState = {
  statistics: [],
  fetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {

  case 'GET_STAT':
    return {
      ...state,
      statistics: action.payload,
      fetching: false
    }

  case 'STATS_LOADING':
    return {
      ...state,
      fetching: true
    }

  default:
    return state
  }
}
