import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import ReactHtmlParser from 'react-html-parser';
import Spinner from '../common/Spinner/Spinner';


class Info extends Component {
  constructor() {
    super();

    this.state = {
      content: '',

      notFound: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ content: '', notFound: false });

    fetch(`${config.fetchURL}/pages?_embed&slug=${this.props.match.params.slug}`)
      .then(res => res.json())
      .then(res => {
        if (res.length > 0) {
          this.setState({
            content: res[0].content.rendered,
            notFound: false
          });
        } else {
          this.setState({ notFound: true });
        }
      });
  }

  render() {
    const { content, notFound } = this.state;

    if (notFound) {
      return (
        <h5 className="text-center my-5 fade-in">
          Nem található tartalom
        </h5>
      );
    } else if (content.length === 0) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else {
      return (
        <div className="post fade-in">
          <div className="post__content">
            {ReactHtmlParser(content)}
          </div>
        </div>
      );
    }
  }
}


Info.propTypes = {
  match: PropTypes.object.isRequired
};


export default Info;
