import React, { Component } from 'react';
import './Products.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProductsByCategory } from '../../actions/productActions';
import ReactHtmlParser from 'react-html-parser';
import Spinner from '../common/Spinner/Spinner';


class Products extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    getProductsByCategory: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.getProductsByCategory(this.props.match.params.slug);
  }

  render() {
    const { products } = this.props;
    let productItems = null;

    if (products.length > 0) {
      productItems = products.map(product => (
        <Link to={`/termek/${product.slug}`} key={product.id} className="product-item">
          <img src={product.image} alt={product.slug} />
          <div className="title">
            {ReactHtmlParser(product.title)}
          </div>
          <div className="product-item-button">
            <i className="material-icons">keyboard_arrow_right</i>
          </div>
        </Link>
      ));
    }

    if (products.length === 0) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else {
      return (
        <div className="products-container container fade-in">
          <Link to={`/kategoriak/${products[0].parent.slug}`} className="product-parent-link" style={{ marginLeft: '10px', marginTop: '15px' }}>
            <div className="icon-wrapper">
              <i className="material-icons">arrow_backward</i>
            </div>
            {products[0].parent.name}
          </Link>

          <div className="products">
            {productItems}
          </div>
        </div>
      );
    }
  }
}


const mapStateToProps = (state) => ({
  products: state.products.products,
  fetching: state.products.fetching
});

export default connect(mapStateToProps, { getProductsByCategory })(Products);