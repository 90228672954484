import React, { Component } from 'react';
import './Partners.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPartners } from '../../../actions/partnerActions';
import CarouselSlider from 'react-carousel-slider';
import Spinner from '../../common/Spinner/Spinner';


class Partners extends Component {
  componentDidMount() {
    this.props.getPartners();
  }

  render() {
    const { logos, fetching } = this.props;
    let logoItems = null;

    if (logos.length > 0) {
      const data = logos.map(item => ({
        imgSrc: item.logo
      }));

      let manner = {
        autoSliding: {interval: "3s"},
        duration: "2s"
      };

      logoItems = (
        <CarouselSlider
          slideItems={data}
          manner={manner}
        />
      );
    }

    if (fetching) {
      return <Spinner style={{ marginTop: '55px', marginBottom: '55px' }} />;
    } else if (logos.length === 0) {
      return null;
    } else {
      return (
        <section className="partners">
          <div className="container">
            {logoItems}
          </div>
        </section>
      );
    }
  }
}


Partners.propTypes = {
  logos: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  getPartners: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
  logos: state.partners.logos,
  fetching: state.partners.fetching
});


export default connect(mapStateToProps, { getPartners })(Partners);
