import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const ReviewIndexItem = ({ index, count }) => {
  let reviewIndexItems = [];
  for (let i = 0; i < count; i++) {
    reviewIndexItems.push(
      <div
        className={classnames('review-index', {
          'active': index === i
        })}
        key={i}
      >
      </div>
    );
  }

  return (
    <div className="row justify-content-center review-index-container">
      {reviewIndexItems}
    </div>
  );
};


ReviewIndexItem.propTypes = {
  index: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired
};

export default ReviewIndexItem;
