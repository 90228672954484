import React, { Component } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import avalonLogo from './avalon.svg';
import phone from '../../../assets/phone.svg';
import fax from '../../../assets/fax.svg';
import home from '../../../assets/home.svg';
import paperPlane from '../../../assets/paper-plane.svg';


class Footer extends Component {
  render() {
    return (
      <footer className="container-fluid">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-5 mb-5 mb-md-0">
              <a href="tel:+3612920649" className="contact-item">
                <img src={phone} alt="phone icon"/>
                <p>+36 1 292 0649</p>
              </a>
              <a href="tel:+36309410542" className="contact-item">
                <div className="icon-placeholder" />
                <p>+36 30 941 0542</p>
              </a>
              <a href="tel:+3612924544" className="contact-item">
                <img src={fax} alt="fax icon"/>
                <p>+36 1 292 4544</p>
              </a>
              <a className="contact-item">
                <img src={home} alt="home icon"/>
                <p>1182 Bp, Vajdahunyad u. 2</p>
              </a>
              <a href="mailto:gumex@gumex.hu" className="contact-item">
                <img src={paperPlane} alt="paper plane icon"/>
                <p>gumex@gumex.hu</p>
              </a>
            </div>

            <div className="col-12 col-md-7 col-lg-5 pr-5">
              <Link to="/">
                <img className="logo mb-3" src={logo} alt="Gumex logo" />
              </Link>
              <p className="rights">A weboldal szöveges tartalma a Gumex Kft. szellemi tulajdonát képezi.</p>
              <p className="rights">A weboldalon közzétett tartalom és fotók külön engedéllyel használhatók!</p>
              <p className="rights d-flex align-items-center">
                Készítette:
                <a className="avalon" href="http://avalonmedia.hu" target="_blank" rel="noopener noreferrer">
                  <img src={avalonLogo} alt="Avalon Media logo" />
                </a>
              </p>
            </div>
          </div>
          <div className="to-up-btn" onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
            <i className="material-icons">arrow_upward</i>
          </div>
        </div>
      </footer>
    );
  }
}


export default Footer;
