import React, { Component } from 'react';
import './Sidenav.scss';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setNavBarState } from '../../../actions/layoutActions';
import classnames from 'classnames';


class Sidenav extends Component {

  closeClickHandler = () => {
    this.props.setNavBarState();
  }

  render() {
    return (
      <div
        className={classnames('sidenav', {
          'visible': this.props.open
        })}
      >

        <div className="close-btn" onClick={this.closeClickHandler}>
          &times;
        </div>

        <div className="top-wrapper"></div>

        <Link to="/" className="menu-item" onClick={this.closeClickHandler}>
          <i className="material-icons">home</i>
          kezdőlap
          <i className="material-icons arrow">keyboard_arrow_right</i>
        </Link>
        <Link to="/kategoriak" className="menu-item" onClick={this.closeClickHandler}>
          <i className="material-icons">view_agenda</i>
          termékek
          <i className="material-icons arrow">keyboard_arrow_right</i>
        </Link>
        <HashLink to="/#szolgaltatasok" smooth className="menu-item" onClick={this.closeClickHandler}>
          <i className="material-icons">build</i>
          szolgáltatásaink
          <i className="material-icons arrow">keyboard_arrow_right</i>
        </HashLink>
        <Link to="/hirek" className="menu-item" onClick={this.closeClickHandler}>
          <i className="material-icons">chrome_reader_mode</i>
          hírek
          <i className="material-icons arrow">keyboard_arrow_right</i>
        </Link>
        <Link to="/informacio/cegunk" className="menu-item" onClick={this.closeClickHandler}>
          <i className="material-icons">info</i>
          cégünk
          <i className="material-icons arrow">keyboard_arrow_right</i>
        </Link>
        <Link to="/kapcsolat" className="menu-item" onClick={this.closeClickHandler}>
          <i className="material-icons">phone</i>
          kapcsolat
          <i className="material-icons arrow">keyboard_arrow_right</i>
        </Link>
      </div>
    );
  }
}


Sidenav.propTypes = {
  setNavBarState: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};


const mapStateToProps = (state) => ({
  open: state.layout.nav.open
});


export default connect(mapStateToProps, { setNavBarState })(Sidenav);
