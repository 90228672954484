import Validator from 'validator';


const validateMessageInput = (data) => {
  const errors = {};

  if (Validator.isEmpty(data.name)) {
    errors.name = 'A név mező kitöltése kötelező';
  }

  if (Validator.isEmpty(data.company)) {
    errors.company = 'A cégnév mező kitöltése kötelező';
  }

  if (!Validator.isEmail(data.email)) {
    errors.email = 'Az email cím formátuma nem megfelelő';
  }
  if (Validator.isEmpty(data.email)) {
    errors.email = 'Az email cím mező kitöltése kötelező';
  }

  if (Validator.isEmpty(data.tel)) {
    errors.tel = 'Az telefonszám mező kitöltése kötelező';
  }

  if (Validator.isEmpty(data.message)) {
    errors.message = 'Az üzenet mező kitöltése kötelező';
  }

  if (!data.terms) {
    errors.terms = 'Az adatkezelési irányelvek elfogadása nélkül nem küldhető üzenet';
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
}


export default validateMessageInput;