import React from 'react';
import './FormCheckBox.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const FormCheckBox = ({ name, checked, onChange, error, disabled, children }) => {
  return (
    <div className={classnames('form-input-group__checkbox', {
      'is-invalid': error
    })}>
      <input
        id="form-input-group__input-id"
        className="form-input-group__input"
        style={{ display: 'none' }}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor="form-input-group__input-id">
        <span>
          <svg width="12px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1" />
          </svg>
        </span>
        <span>
          {children}
        </span>
      </label>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};


FormCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};


FormCheckBox.defaultProps = {
  disabled: false
};

export default FormCheckBox;
