import React, { Fragment } from 'react';
import './Pagination.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';


const Pagination = ({ pages, page, setPage }) => {

  const paginationItems = (
    <Fragment>
      {(page + 1 > pages && page - 2 > 0) &&
        <li
          className="page-item"
          onClick={setPage.bind(this, page - 2)}
        ><div className="page-link">{page - 2}</div></li>
      }

      {(page - 1 > 0) &&
        <li
          className="page-item"
          onClick={setPage.bind(this, page - 1)}
        ><div className="page-link">{page - 1}</div></li>
      }
      <li className="page-item active"><div className="page-link">{page}</div></li>
      {(page + 1 <= pages) &&
        <li
          className="page-item"
          onClick={setPage.bind(this, page + 1)}
        ><div className="page-link">{page + 1}</div></li>
      }

      {(page - 1 === 0 && page + 2 <= pages) &&
        <li
          className="page-item"
          onClick={setPage.bind(this, page + 2)}
        ><div className="page-link">{page + 2}</div></li>
      }
    </Fragment>
  );

  return (
    <ul className="pagination justify-content-center mt-5">
      <li
        className={classnames('page-item', {
          'disabled': page === 1
        })}
        onClick={setPage.bind(this, page - 1)}
      >
        <div className="page-link">
          <span aria-hidden="true">&laquo;</span>
          <span className="sr-only">Előző</span>
        </div>
      </li>

      {paginationItems}

      <li
        className={classnames('page-item', {
          'disabled': page === pages
        })}
        onClick={setPage.bind(this, page + 1)}
      >
        <div className="page-link">
          <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">Következő</span>
        </div>
      </li>
    </ul>
  )
}


Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};


export default Pagination;
