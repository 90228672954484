import config from '../config';


// Get statistics
export const getStat = () => async (dispatch) => {
  dispatch(setStatsLoading());

  const res = await fetch(`${config.fetchURL}/statisztikak`);
  const json = await res.json();

  const reviews = json.map(item => ({
    name: item.title.rendered,
    value: item.acf.ertek,
    icon: item.acf.ikon
  }));

  dispatch({
    type: 'GET_STAT',
    payload: reviews
  });

};


// Statistics loading
export const setStatsLoading = () => {
  return {
    type: 'STATS_LOADING'
  };
};
