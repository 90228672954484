import React, { Component } from 'react';
import './Contact.scss';
import ContactBlock from '../Home/Contact/Contact';
import Form from './Form/Form';
import Reveal from 'react-reveal/Reveal';
import phone from '../../assets/phone.svg';
import home from '../../assets/home.svg';
import paperPlane from '../../assets/paper-plane.svg';


class Contact extends Component {
  render() {
    return (
      <div className="contact-wrapper">

        <div className="container-flud contact-fluid">
          <div className="contact-item-wrapper">
            <Reveal effect="grow-in-left">
              <div className="contact-item">
                <img src={paperPlane} alt="paper plane icon"/>
                <div>
                  <p className="contact-item__title">Email:</p>
                  <a href="mailto:gumex@gumex.hu" className="contact-item__value">gumex@gumex.hu</a>
                </div>
              </div>
            </Reveal>
            <Reveal effect="grow-in-bottom">
              <div className="contact-item">
                <img src={phone} alt="phone icon"/>
                <div>
                  <p className="contact-item__title">Telefon:</p>
                  <a href="+3612920649" className="contact-item__value">+36 1 292 0649</a>
                  <a href="+36309410542" className="contact-item__value">+36 30 941 0542</a>
                </div>
              </div>
            </Reveal>
            <Reveal effect="grow-in-right">
              <a className="contact-item">
                <img src={home} alt="home icon"/>
                <div>
                  <p className="contact-item__title">Cím:</p>
                  <p className="contact-item__value">1182 Budapest, XVIII. kerület, Vajdahunyad utca 2. </p>
                  <p className="contact-item__value">(Pestszentlőrinc)</p>
                  <p className="contact-item__value">1680 Budapest, Pf.:231.</p>
                </div>
              </a>
            </Reveal>
          </div>
        </div>

        <ContactBlock />

        <Form />
      </div>
    );
  }
}


export default Contact;
