import React, { Component } from 'react';
import './Products.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getChildCategories } from '../../actions/productActions';
import ReactHtmlParser from 'react-html-parser';
import Spinner from '../common/Spinner/Spinner';


class Categories extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    getChildCategories: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.getChildCategories(this.props.match.params.slug);
  }

  render() {
    const { categories } = this.props;
    let categoryItems = null;

    if (categories.length > 0) {
      categoryItems = categories.map((category, index) => (
        <Link to={`/termekek/${category.slug}`} key={index} className="product-item">
          <img src={category.image} alt={category.slug} />
          <div className="title">
            {ReactHtmlParser(category.name)}
          </div>
          <div className="product-item-button">
            <i className="material-icons">keyboard_arrow_right</i>
          </div>
        </Link>
      ));
    }

    if (categories.length === 0) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else {
      return (
        <div className="products-container container fade-in">
          <Link to="/kategoriak" className="product-parent-link" style={{ marginLeft: '10px', marginTop: '15px' }}>
            <div className="icon-wrapper">
              <i className="material-icons">arrow_backward</i>
            </div>
            Főkategóriák
          </Link>

          <div className="products">
            {categoryItems}
          </div>
        </div>
      );
    }
  }
}


const mapStateToProps = (state) => ({
  categories: state.products.categories.sub,
  fetching: state.products.fetching
});

export default connect(mapStateToProps, { getChildCategories })(Categories);