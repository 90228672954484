import React, { Component } from 'react';
import './Reviews.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getReviews } from '../../../actions/reviewActions';
import classnames from 'classnames';
import Reveal from 'react-reveal/Reveal';
import Particles from 'react-particles-js';
import Spinner from '../../common/Spinner/Spinner';
import ReviewItem from './ReviewItem';
import ReviewIndex from './ReviewIndex';
import image from './image.jpg';
import noiseImage from '../../../assets/noise.png';


class Reviews extends Component {
  constructor() {
    super();

    this.state = {
      index: 0,
      next: false,
      disabled: false
    };
  }

  componentDidMount() {
    this.props.getReviews();

    this.reviewTimeout();
  }

  reviewTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.nextReviewHandler();
    }, 5000);
  }

  nextReviewHandler = () => {
    this.reviewTimeout();
    this.setState((prevState, props) => {
      const index = prevState.index < props.reviews.length - 1 ? prevState.index + 1 : 0;
      return {
        index,
        disabled: true,
        next: true
      };
    });

    setTimeout(() => {
      this.setState({ disabled: false });
    }, 500);
  }

  prevReviewHandler = () => {
    this.reviewTimeout();
    this.setState((prevState, props) => {
      const index = prevState.index > 0 ? prevState.index - 1 : props.reviews.length - 1;
      return {
        index,
        disabled: true,
        next: false
      };
    });

    setTimeout(() => {
      this.setState({ disabled: false });
    }, 500);
  }

  render() {
    const { reviews, fetching } = this.props;
    const { index, next, disabled } = this.state;
    let reviewItem = null;

    if (reviews.length > 0) {
      reviewItem = <ReviewItem id={index} text={reviews[index].text} name={reviews[index].name} next={next} />;
    }

    if (fetching) {
      return (
        <section className="reviews">
          <Spinner style={{ marginTop: '150px', marginBottom: '150px' }} color="#ffffff" />
        </section>
      );
    } else if (reviews.length < 1) {
      return null;
    } else {
      return (
        <section className="reviews" id="szolgaltatasok">

          <Particles
            params={{
              particles: {
                number: {
                  value: window.innerWidth > 992 ? 30 : 0
                },
                color: {
                  value: '#000000'
                },
                line_linked: {
                  color: '#777777'
                }
              }
            }}
            className={classnames('particles', {
              'disabled': disabled
            })}
          />

          <Reveal effect="grow-in-bottom">
            <div className="container-fluid reviews-wrapper">
              <h1 className="section__name text-center">
                <strong>Szolgáltatásaink</strong>
              </h1>
              <div className="header-sep"></div>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="image-container">
                  <img src={image} alt="business image" />
                </div>
                <div className="text-container">

                  {reviewItem}

                  <div
                    className={classnames('col-2 arrow arrow--left', {
                      'disabled': disabled
                    })} onClick={this.prevReviewHandler}
                  >
                    <i className="material-icons">arrow_back</i>
                  </div>
                  <div
                    className={classnames('col-2 arrow arrow--right', {
                      'disabled': disabled
                    })} onClick={this.nextReviewHandler}
                  >
                    <i className="material-icons">arrow_forward</i>
                  </div>

                  <ReviewIndex index={index} count={reviews.length} />

                </div>
              </div>
            </div>
          </Reveal>

          <div className="background"></div>
          <div className="noise" style={{ backgroundImage: `url(${noiseImage})` }}></div>
        </section>
      );
    }
  }
}


Reviews.propTypes = {
  reviews: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  getReviews: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
  reviews: state.reviews.reviews,
  fetching: state.reviews.fetching
});


export default connect(mapStateToProps, { getReviews })(Reviews);
