const initialState = {
  categories: {
    main: [],
    sub: []
  },
  products: [],
  product: {},

  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {

  case 'GET_MAIN_CATEGORIES':
    return {
      ...state,
      categories: {
        ...state.categories,
        main: action.payload
      },
      fetching: false
    };

  case 'GET_CHILD_CATEGORIES':
    return {
      ...state,
      categories: {
        ...state.categories,
        sub: action.payload
      },
      fetching: false
    };

  case 'GET_PRODUCTS':
    return {
      ...state,
      products: action.payload,
      fetching: false
    };

  case 'GET_PRODUCT':
    return {
      ...state,
      product: action.payload,
      products: [],
      fetching: false
    };

  case 'PRODUCTS_LOADING':
    return {
      ...state,
      fetching: true
    };

  default:
    return state;
  }
};
