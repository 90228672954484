import React, { Component } from 'react';
import './Posts.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPosts } from '../../actions/postActions';

import Spinner from '../common/Spinner/Spinner';
import PostItem from './PostItem';
import Pagination from '../common/Pagination/Pagination';
import Form from '../Contact/Form/Form';


class Posts extends Component {
  static propTypes = {
    posts: PropTypes.array.isRequired,
    count: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    fetching: PropTypes.bool.isRequired,
    getPosts: PropTypes.func.isRequired
  };

  state = {
    page: 1,
    limit: 9
  };

  componentDidMount() {
    this.props.getPosts(this.state.limit, this.state.page);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.props.getPosts(this.state.limit, this.state.page);
    }
  }

  setPage = (page) => {
    this.setState({ page });
  }

  render() {
    const { posts, count } = this.props;
    const pages = Math.ceil(count / this.state.limit);
    let postItems;

    postItems = posts.map((post, index) => (
      <PostItem
        key={index}
        title={post.title}
        slug={post.slug}
        excerpt={post.excerpt}
        date={post.date}
        image={post.image}
      />
    ));

    if (this.props.fetching) return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    else {
      return (
          <div className="posts fade-in">
            <div className="d-flex flex-wrap justify-content-center p-sm-1 mw-1000 m-auto">
              {postItems}

            </div>

            {pages !== 1 &&
              <Pagination
                pages={pages}
                page={this.state.page}
                setPage={this.setPage}
              />
            }

            <div className="mt-5">
              <Form />
            </div>

          </div>
      )
    }

  }
}


const mapStateToProps = (state) => ({
  posts: state.posts.all,
  count: state.posts.count,
  fetching: state.posts.fetching
});

export default connect(mapStateToProps, { getPosts })(Posts);
