import imagePlaceholder from '../assets/image-placeholder.svg';
import config from '../config';


// Get all posts
export const getPosts = (limit, page) => async (dispatch) => {
  dispatch(setPostsLoading());

  const res = await fetch(`${config.fetchURL}/hirek?_embed&per_page=${limit}&page=${page}`);
  const data = await res.json();
  const count = res.headers.get('X-WP-Total');

  dispatch(setPostsCount(count));

  const news = data.map(item => ({
    title: item.title.rendered,
    excerpt: item.excerpt.rendered,
    slug: item.slug,
    date: item.date,
    image: (item._embedded) ? item._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : imagePlaceholder
  }));

  dispatch({
    type: 'GET_POSTS',
    payload: news
  });
};


// Get the newest 3 post
export const getRecentPosts = () => async (dispatch) => {
  dispatch(setPostsLoading());

  const res = await fetch(`${config.fetchURL}/hirek?_embed&per_page=3`);
  const data = await res.json();

  const news = data.map(item => ({
    title: item.title.rendered,
    excerpt: item.excerpt.rendered,
    slug: item.slug,
    date: item.date,
    image: (item._embedded) ? item._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : imagePlaceholder
  }));

  dispatch({
    type: 'GET_RECENT_POSTS',
    payload: news
  });
};


// Get post by slug
export const getPostBySlug = (slug) => async (dispatch) => {
  dispatch(setPostsLoading());

  const res = await fetch(`${config.fetchURL}/hirek?_embed&slug=${slug}`);
  const data = await res.json();

  const post = data.map(item => ({
    title: item.title.rendered,
    content: item.content.rendered,
    slug: item.slug,
    date: item.date,
    image: (item._embedded) ? item._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : imagePlaceholder,
    keywords: item.acf.kulcsszavak || null
  }));

  dispatch({
    type: 'GET_POST',
    payload: post[0]
  });
};


// Get posts count
export const setPostsCount = (count) => {
  return {
    type: 'GET_POSTS_COUNT',
    payload: count
  };
};


// Posts loading
export const setPostsLoading = () => {
  return {
    type: 'POSTS_LOADING'
  };
};
