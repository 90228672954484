const initialState = {
  single: {},
  recent: [],
  all: [],
  count: 0,
  fetching: false
};


export default (state = initialState, action) => {
  switch (action.type) {

  case 'GET_POSTS':
    return {
      ...state,
      all: action.payload,
      fetching: false
    };

  case 'GET_RECENT_POSTS':
    return {
      ...state,
      recent: action.payload,
      fetching: false
    };

  case 'GET_POSTS_COUNT':
    return {
      ...state,
      count: action.payload
    };

  case 'GET_POST':
    return {
      ...state,
      single: action.payload
    };

  case 'POSTS_LOADING':
    return {
      ...state,
      fetching: true
    };

  default:
    return state;
  }
}
