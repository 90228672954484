import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPostBySlug } from '../../actions/postActions';
import { HashLink } from 'react-router-hash-link';
import Moment from 'react-moment';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import Helmet from 'react-helmet';
import Spinner from '../common/Spinner/Spinner';
import Form from  '../Contact/Form/Form';


class Post extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
    getPostBySlug: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    moment.updateLocale('hu', {
      months: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December']
    });

    this.props.getPostBySlug(this.props.match.params.slug);
  }

  render() {
    const { post } = this.props;

    if (Object.keys(post).length === 0 || post.slug !== this.props.match.params.slug) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else {
      return (
        <Fragment>
          <div className="post fade-in">

            <Helmet>
              <title>{`${post.title} | Gumex`}</title>
              <meta name="description" content={`Gumex, ${post.title}`} />
              { post.keywords && <meta name="keywords" content={post.keywords} /> }
              <meta property="og:title" content={`${post.title} | Gumex`} />
              <meta property="og:description" content={`Gumex, ${post.title}`} />
              <meta property="og:image" content={post.image} />
            </Helmet>

            <div className="post-share-buttons">
              <button>
                <HashLink to="#form" smooth>
                  <i className="material-icons">mail</i>
                </HashLink>
              </button>
            </div>

            <h2>{post.title}</h2>
            <Moment locale="hu" format="YYYY MMMM DD.">{new Date(post.date)}</Moment>
            <img src={post.image} alt={post.slug} className="my-3 w-100" />
            <div className="post__content">
              {ReactHtmlParser(post.content.replace(/<a href=/g, '<a target="_blank" href='))}
            </div>

          </div>

          <div id="form">
            <Form title={post.title} />
          </div>
        </Fragment>
      );
    }
  }
}


const mapStateToProps = (state) => ({
  post: state.posts.single,
  fetching: state.posts.fetching
});

export default connect(mapStateToProps, { getPostBySlug })(Post);
