import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProductBySlug } from '../../actions/productActions';
import { HashLink } from 'react-router-hash-link';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Form from '../Contact/Form/Form';

import Spinner from '../common/Spinner/Spinner';


class Product extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    getProductBySlug: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.getProductBySlug(this.props.match.params.slug);
  }

  render() {
    const { product } = this.props;

    console.log(product.content);

    if (Object.keys(product).length === 0 || product.slug !== this.props.match.params.slug) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else {
      return (
        <Fragment>
          <div className="post fade-in">

            <Helmet>
              <title>{`${ReactHtmlParser(product.title)} | Gumex`}</title>
              <meta name="description" content={`Gumex, ${product.title}`} />
              { product.keywords && <meta name="keywords" content={product.keywords} /> }
              <meta property="og:title" content={`${product.title} | Gumex`} />
              <meta property="og:description" content={`Gumex, ${product.title}`} />
              <meta property="og:image" content={product.image} />
            </Helmet>

            <Link to={`/termekek/${product.parent.slug}`} className="product-parent-link">
              <div className="icon-wrapper">
                <i className="material-icons">arrow_backward</i>
              </div>
              {product.parent.name}
            </Link>

            <div className="post-share-buttons">
              <button>
                <HashLink to="#form" smooth>
                  <i className="material-icons">mail</i>
                </HashLink>
              </button>
            </div>

            <h2 className="mb-4">{ReactHtmlParser(product.title)}</h2>
            <div className="post__content">
              {ReactHtmlParser(product.content.replace(/<a href=/g, '<a target="_blank" href='))}
            </div>

            <hr className="mt-5"/>
          </div>

          <div id="form">
            <Form title={product.title} />
          </div>
        </Fragment>
      );
    }
  }
}


const mapStateToProps = (state) => ({
  product: state.products.product,
  fetching: state.products.fetching
});

export default connect(mapStateToProps, { getProductBySlug })(Product);
