import imagePlaceholder from '../assets/image-placeholder.svg';
import config from '../config';


// Get main categories
export const getMainCategories = () => async (dispatch) => {
  dispatch(setProductsLoading());

  const res = await fetch(`${config.fetchURL}/categories?parent=0`);
  const data = await res.json();

  const categories = data
    .slice(0, 6)
    .filter(category => category.slug !== 'egyeb')
    .map(category => ({
      name: category.name,
      slug: category.slug,
      image: category.acf.kep
    }));

  dispatch({
    type: 'GET_MAIN_CATEGORIES',
    payload: categories
  });
};


// Get child categories
export const getChildCategories = (slug) => async (dispatch) => {
  dispatch({
    type: 'GET_CHILD_CATEGORIES',
    payload: []
  });
  dispatch(setProductsLoading());

  const category = await getCategoryBySlug(slug);

  const res = await fetch(`${config.fetchURL}/categories?parent=${category.id}`);
  const data = await res.json();

  const categories = data
    .filter(category => category.slug !== 'egyeb')
    .map(category => ({
      id: category.id,
      name: category.name,
      slug: category.slug,
      image: category.acf.kep,
      parent: category.parent
    }));

  dispatch({
    type: 'GET_CHILD_CATEGORIES',
    payload: categories
  });
};


// Get products by category
export const getProductsByCategory = (slug) => async (dispatch) => {
  dispatch({
    type: 'GET_PRODUCTS',
    payload: []
  });
  dispatch(setProductsLoading());

  const category = await getCategoryBySlug(slug);


  const res = await fetch(`${config.fetchURL}/termekek?_embed&categories=${category.id}&per_page=99`);
  const data = await res.json();

  const resParentCategory = await fetch(`${config.fetchURL}/categories/${category.parent}`);
  const dataParentCategory = await resParentCategory.json();

  const parent = {
    id: dataParentCategory.id,
    name: dataParentCategory.name,
    slug: dataParentCategory.slug
  };

  const products = data
    .map(product => ({
      id: product.id,
      title: product.title.rendered,
      slug: product.slug,
      image: (product._embedded && product._embedded['wp:featuredmedia']) ? product._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url : imagePlaceholder,
      parent
    }));

  dispatch({
    type: 'GET_PRODUCTS',
    payload: products
  });
};


// Search products
export const searchProducts = (searchString) => async (dispatch) => {
  dispatch({
    type: 'GET_PRODUCTS',
    payload: []
  });
  dispatch(setProductsLoading());

  const res = await fetch(`${config.fetchURL}/termekek?_embed&search=${searchString}&per_page=99`);
  const data = await res.json();

  const products = data
    .map(product => ({
      id: product.id,
      title: product.title.rendered,
      slug: product.slug,
      image: (product._embedded && product._embedded['wp:featuredmedia']) ? product._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url : imagePlaceholder
    }));

  dispatch({
    type: 'GET_PRODUCTS',
    payload: products
  });
};


// Get product by slug
export const getProductBySlug = (slug) => async (dispatch) => {
  dispatch(setProductsLoading());

  const res = await fetch(`${config.fetchURL}/termekek?_embed&slug=${slug}`);
  const data = await res.json();

  const resParentCategory = await fetch(`${config.fetchURL}/categories/${data[0].categories[0]}`);
  const dataParentCategory = await resParentCategory.json();

  const parent = {
    id: dataParentCategory.id,
    name: dataParentCategory.name,
    slug: dataParentCategory.slug
  };

  const product = data.map(item => ({
    title: item.title.rendered,
    content: item.acf.termek_leirasa,
    slug: item.slug,
    keywords: item.acf.kulcsszavak || null,
    parent
  }));

  dispatch({
    type: 'GET_PRODUCT',
    payload: product[0]
  });
};


// Products loading
export const setProductsLoading = () => ({
  type: 'PRODUCTS_LOADING'
});


// Get category ID by slug
const getCategoryBySlug = async (slug) => {
  const res = await fetch(`${config.fetchURL}/categories?slug=${slug}`);
  const data = await res.json();

  return data.length > 0 ? data[0] : { };
};
