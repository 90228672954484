import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';


const ReviewItem = (props) => {
  return (
    <ReactCSSTransitionGroup
      transitionName={props.next ? 'review-item-right' : 'review-item-left'}
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      <div key={props.id} className="text">
        {props.text}
      </div>
    </ReactCSSTransitionGroup>
  );
};


ReviewItem.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  next: PropTypes.bool.isRequired
};


export default ReviewItem;
