import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchProducts } from '../../actions/productActions';
import { Link } from 'react-router-dom';
import Spinner from '../common/Spinner/Spinner';
import ReactHtmlParser from 'react-html-parser';


class Products extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    searchProducts: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.searchProducts(this.props.match.params.searchString);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.searchString !== this.props.match.params.searchString) {
      this.props.searchProducts(this.props.match.params.searchString);
    }
  }

  render() {
    const { products, fetching } = this.props;
    let productItems = null;

    if (products.length > 0) {
      productItems = products.map(product => (
        <Link to={`/termek/${product.slug}`} key={product.id} className="product-item">
          <img src={product.image} alt={product.slug} />
          <div className="title">
            {ReactHtmlParser(product.title)}
          </div>
          <div className="product-item-button">
            <i className="material-icons">keyboard_arrow_right</i>
          </div>
        </Link>
      ));
    }

    if (fetching) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else if (products.length === 0) {
      return (
        <h5 className="text-center my-5 fade-in">
          Nincs találat
        </h5>
      );
    } else {
      return (
        <div className="products container fade-in">
          {productItems}
        </div>
      );
    }
  }
}


const mapStateToProps = (state) => ({
  products: state.products.products,
  fetching: state.products.fetching
});

const mapDispatchToProps = {
  searchProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
