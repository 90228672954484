import config from '../config';


// Get partner logos
export const getPartners = () => async (dispatch) => {
  dispatch(setPartnersLoading());

  const res = await fetch(`${config.fetchURL}/partnerek`);
  const json = await res.json();

  const partners = json.map(item => ({
    name: item.title.rendered,
    logo: item.acf.logo
  }));

  dispatch({
    type: 'GET_PARTNERS',
    payload: partners
  });

};


// Partners loading
export const setPartnersLoading = () => {
  return {
    type: 'PARTNERS_LOADING'
  };
};
