import React, { Component } from 'react';
import './Contact.scss';
import config from '../../../config';
import ReactHTMLParser from 'react-html-parser';
import arrow from '../../../assets/forward.svg';
import noiseImage from '../../../assets/noise.png';
import Reveal from 'react-reveal/Reveal';
import Spinner from '../../common/Spinner/Spinner';


class Contact extends Component {
  state = {
    content: ''
  }

  componentDidMount() {
    fetch(`${config.fetchURL}/pages?_embed&slug=kapcsolat`)
      .then(res => res.json())
      .then(res => {
        this.setState({
          content: res[0].content.rendered
        });
      });
  }

  render() {
    if (this.state.content.length === 0) {
      return <Spinner style={{ marginTop: '200px', marginBottom: '200px' }} />;
    } else {
      return (
        <section className="contact">

          <Reveal effect="slide-in-right">

            <div className="contact-container d-flex flex-wrap align-items-end justify-content-center">

              <div className="info">
                {ReactHTMLParser(this.state.content)}
              </div>

              <div className="map">
                <div className="map-container">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2698.9053658252224!2d19.202248314826303!3d47.43328860792874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741c2223fa4e551%3A0xe6ae92600ab053ac!2sGumex+M%C5%B1szaki+Ker.+Kft.!5e0!3m2!1shu!2shu!4v1534883374517" title="Google Maps" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe>
                </div>
                <a className="info__button" href="https://www.google.com/maps/dir/?api=1&amp;destination=1182 Budapest, Vajdahunyad utca 2." target="_blank" rel="noopener noreferrer">
                  <p>
                    Útvonaltervezés
                  </p>
                  <img src={arrow} alt="arrow icon" />
                </a>
              </div>
            </div>

          </Reveal>

          <div className="block">
            <div className="noise" style={{ backgroundImage: `url(${noiseImage})` }}></div>
          </div>
        </section>
      );
    }
  }
}


export default Contact;
