import { combineReducers } from "redux";
import layoutReducer from './layoutReducer';
import postsReducer from './postsReducer';
import partnersReducer from './partnersReducer';
import reviewsReducer from './reviewsReducer';
import statisticsReducer from './statisticsReducer';
import productsReducer from './productsReducer';


export default combineReducers({
  layout: layoutReducer,
  posts: postsReducer,
  partners: partnersReducer,
  reviews: reviewsReducer,
  statistics: statisticsReducer,
  products: productsReducer
});
