import React from 'react';
import './LoaderButton.scss';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/Spinner';
import classnames from 'classnames';


const LoaderButton = (props) => {
  return (
    <button className={classnames('my-btn my-btn--white btn-loader', {
        'success': props.check,
        'fail': props.fail
      })}
      >
      <div className="loader-wrapper">
        <Spinner
          stroke="5"
          color="light"
          hide={!props.load}
        />
      </div>
      <p className={classnames({
        'hide': props.check || props.fail || props.load
      })}
      >{props.value}</p>
      <div className={classnames('check', {
        'hide': !props.check
      })}>
        <i className="material-icons">check</i>
      </div>
      <div className={classnames('fail', {
        'hide': !props.fail
      })}>
        <i className="material-icons">close</i>
      </div>
    </button>
  );
};


LoaderButton.propTypes = {
  value: PropTypes.string.isRequired,
  load: PropTypes.bool.isRequired,
  check: PropTypes.bool.isRequired,
  fail: PropTypes.bool.isRequired
};


export default LoaderButton;
