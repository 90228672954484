import React, { Component } from 'react';
import './Header.scss';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setNavBarState, setSearchBarState } from '../../../actions/layoutActions';
import Reveal from 'react-reveal/Reveal';
import logo from '../../../assets/logo.svg';
import logoBlue from '../../../assets/logo-blue.svg';


class Header extends Component {
  constructor() {
    super();

    this.state = {
      small: false
    };
  }

  componentDidMount() {
    if (window.location.pathname !== '/') {
      this.setState({ small: true });
    } else {
      if (window.matchMedia('(min-width: 992px) and (max-width: 1600px)').matches) {
        this.setState({ small: true });
        window.removeEventListener('scroll', this.handleScroll);
      } else {
        window.addEventListener('scroll', this.handleScroll);
      }
    }

    this.props.history.listen(() => {
      if (window.location.pathname !== '/') {
        this.setState({ small: true });
        window.removeEventListener('scroll', this.handleScroll);
      } else {
        if (window.matchMedia('(min-width: 992px) and (max-width: 1600px)').matches) {
          this.setState({ small: true });
          window.removeEventListener('scroll', this.handleScroll);
        } else {
          this.setState({ small: false });
          window.addEventListener('scroll', this.handleScroll);
        }
      }
    });
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState({ small: true });
    } else {
      this.setState({ small: false });
    }
  }

  render() {
    return (
      <Reveal effect="slide-in-top">
        <header className={classnames({
          'header--small': this.state.small
        })}>
          <nav>
            <Link to="/" className="nav__item nav__item--logo">
              <img src={logo} className="nav__item--logo--light" alt="Gumex logo" />
              <img src={logoBlue} className="nav__item--logo--dark" alt="Gumex logo" />
            </Link>
            <Link to="/" className="nav__item nav__item--hover">Főoldal</Link>
            <Link to="/kategoriak" className="nav__item nav__item--hover">Termékek</Link>
            <HashLink to="/#szolgaltatasok" smooth className="nav__item nav__item--hover">Szolgáltatásaink</HashLink>
            <Link to="/hirek" className="nav__item nav__item--hover">Hírek</Link>
            <Link to="/informacio/cegunk" className="nav__item nav__item--hover">Cégünk</Link>
            <Link to="/kapcsolat" className="nav__item nav__item--hover">Kapcsolat</Link>
            <div className="nav__item nav__item--mobile" onClick={this.props.setNavBarState}>
              <i className="material-icons">menu</i>
            </div>
            <div className="nav__item nav__item--search" onClick={this.props.setSearchBarState}>
              <i className="material-icons">search</i>
            </div>
          </nav>
        </header>
      </Reveal>
    );
  }
}


Header.propTypes = {
  setNavBarState: PropTypes.func.isRequired,
  setSearchBarState: PropTypes.func.isRequired
};


export default connect(null, { setNavBarState, setSearchBarState })(withRouter(Header));
