import React, { Component } from 'react';
import './SearchBar.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSearchBarState } from '../../../actions/layoutActions';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';


class SearchBar extends Component {
  state = {
    text: ''
  };

  searchInput = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      setTimeout(() => {
        this.searchInput.current.focus();
      }, 100);
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.text.length === 0) return;

    const { text } = this.state;

    this.props.history.push(`/kereses/termek/${text}`);

    this.setState({ text: '' });
    this.props.setSearchBarState();
  }

  render() {
    return (
      <div className={classnames('searchbar-wrapper', {
        'visible': this.props.open
      })}>
        <div className="search-icon" onClick={this.onSubmit}>
          <i className="material-icons">search</i>
        </div>
        <div className="search-input">
          <span className={classnames('placeholder', {
            'hide': this.state.text.length > 0
          })}>Keresés</span>
          <form onSubmit={this.onSubmit} autoComplete="off">
            <input
              type="search"
              name="text"
              ref={this.searchInput}
              onChange={this.onChange}
              value={this.state.text}
            />
          </form>
        </div>
        <div className="close-btn" onClick={this.props.setSearchBarState}>
          <i className="material-icons">close</i>
        </div>
      </div>
    );
  }
}


SearchBar.propTypes = {
  history: PropTypes.object.isRequired,
  setSearchBarState: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  open: state.layout.search.open
});

const mapDispatchToProps = {
  setSearchBarState
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBar));
