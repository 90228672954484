import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import facebookIcon from '../../assets/facebook.svg';
import twitterIcon from '../../assets/twitter.svg';


const PostItem = (props) => {
  moment.updateLocale('hu', {
    months: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December']
  });

  const { title, slug, excerpt, date, image } = props;

  return (
    <div className="post-item--small">
      <div className="post-share-buttons">
        <FacebookShareButton url={`${window.location.origin}/hirek/${slug}`}>
          <img src={facebookIcon} alt="facebook icon"/>
        </FacebookShareButton>
        <TwitterShareButton url={`${window.location.origin}/hirek/${slug}`}>
          <img src={twitterIcon} alt="twitter icon"/>
        </TwitterShareButton>
      </div>
      <Link to={`/hirek/${slug}`}>
        <div className="image">
          <img src={image} alt={title} className="h-100" />
        </div>
        <div className="data">
          <h1>{title}</h1>
          <div className="first-paragraph">{ReactHtmlParser(excerpt.replace(' [&hellip;]', '...'))}</div>
          <div className="date">
            <Moment locale="hu" format="MMMM DD.">{new Date(date)}</Moment>
          </div>
        </div>
      </Link>
    </div>
  );
};


PostItem.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};


export default PostItem;