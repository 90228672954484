import React, { Component } from 'react';
import './Categories.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMainCategories, getChildCategories } from '../../../actions/productActions';
import Spinner from '../../common/Spinner/Spinner';
import Swiper from 'react-id-swiper';
import Reveal from 'react-reveal/Reveal';


class Categories extends Component {
  constructor() {
    super();

    this.state = {
      active: ''
    };
  }

  componentDidMount() {
    this.props.getMainCategories();
  }

  onClickHandler = (e) => {
    if (e.target.classList.contains('category')) {
      this.setState({ active: e.target.dataset.name });
      this.props.getChildCategories(e.target.dataset.name);
    }
  }

  onCloseHandler= () => {
    this.setState({ active: '' });
  }

  onLoad = (e) => {
    e.target.classList.remove('blur');
  }

  render() {
    const { categories } = this.props;
    let categoryItems = null;

    if (categories.main.length > 0) {
      categoryItems = categories.main.map((category, index) => (
        <div
          key={index}
          className={classnames(`category`, {
            'active': this.state.active === category.slug,
            'not-active': this.state.active !== '' && this.state.active !== category.slug
          })}
          data-id={index}
          data-name={category.slug}
          onClick={this.onClickHandler}
        >
          <span className="image-wrapper">
            <img src={category.image} alt={category.name} className="fade-in blur" onLoad={this.onLoad} />
          </span>
          <div className="clicking-mouse">
            <div className="clicking-mouse__line-horizontal"></div>
            <div className="clicking-mouse__line-vertical"></div>
          </div>
          <div className="title fade-in">
            <div className="category-index main">{`0${index + 1}`}</div>
            <h4>{category.name}</h4>
          </div>
          <div className="close-btn" onClick={this.onCloseHandler}>
            &times;
          </div>
          <div className={classnames('sub-categories', {
            'disabled': categories.sub.length === 0
          })}>
            {
              (categories.sub.length > 0)
              ?
                categories.sub.map((category, index) => (
                  <Link
                    key={category.id}
                    to={`/termekek/${category.slug}`}
                    className="sub-category fade-in"
                  >
                    <div className="clicking-mouse">
                      <div className="clicking-mouse__line-horizontal"></div>
                      <div className="clicking-mouse__line-vertical"></div>
                    </div>
                    <div className="sub-category__name">
                      <div className="category-index">{`0${index + 1}`}</div>
                      <h4>{category.name}</h4>
                    </div>
                    <img src={category.image} alt={category.name} />
                  </Link>
                ))
              :
              <Spinner stroke="4" />
            }
          </div>
        </div>
      ));
    }

    if (categories.main.length === 0) {
      return (
        <div className="categories-wrapper">
          <Reveal effect="slide-in-left">
            <div className="categories-wrapper-bg"></div>
          </Reveal>
          <Reveal effect="slide-in-right">
            <section className="categories">
              <div className="category dummy">
                <Spinner color="#ddd" stroke="4" />
                <div className="title fade-in"></div>
              </div>
              <div className="category dummy">
                <Spinner color="#ddd" stroke="4" />
                <div className="title fade-in"></div>
              </div>
              <div className="category dummy">
                <Spinner color="#ddd" stroke="4" />
                <div className="title fade-in"></div>
              </div>
              <div className="category dummy">
                <Spinner color="#ddd" stroke="4" />
                <div className="title fade-in"></div>
              </div>
              <div className="category dummy">
                <Spinner color="#ddd" stroke="4" />
                <div className="title fade-in"></div>
              </div>

            </section>
          </Reveal>
        </div>
      );
    } else {
      const params = {
        // Responsive breakpoints
        breakpoints: {
          992: {
            spaceBetween: 5,
            slidesOffsetBefore: 150,
            slidesPerView: 3.5
          },
          768: {
            spaceBetween: 5,
            slidesOffsetBefore: 50,
            slidesPerView: 3
          },
          650: {
            spaceBetween: 5,
            slidesOffsetBefore: 50,
            slidesPerView: 2.5
          },
          550: {
            spaceBetween: 5,
            slidesOffsetBefore: 50,
            slidesPerView: 2
          },
          480: {
            spaceBetween: 5,
            slidesOffsetBefore: 50,
            slidesPerView: 1.5
          }
        }
      };

      return (
        <div className="categories-wrapper">
          <div className="categories-wrapper-bg"></div>
          <section className="categories">

            <Swiper
              containerClass={classnames('swiper-container', {
                'swiper-no-swiping': window.innerWidth > 992
              })}
              {...params}
              >
              {categoryItems}
            </Swiper>

            <div className="section-title-wrapper">
              <div className="bar">
                <i className="material-icons">arrow_forward</i>
              </div>
              <div className="section-title">Termékkategóriák</div>
            </div>

          </section>
        </div>
      );
    }
  }
}


Categories.propTypes = {
  categories: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  getMainCategories: PropTypes.func.isRequired,
  getChildCategories: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
  categories: state.products.categories,
  fetching: state.products.fetching
});


export default connect(mapStateToProps, { getMainCategories, getChildCategories })(Categories);
