import React from 'react';
import './Spinner.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const Spinner = (props) => {
  return (
    <div
      className={classnames('loader-circle fade-in', {
        'hide': props.hide,
        'dark': props.color === "dark",
        'light': props.color === "light"
      })}
      style={props.style}
    >
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={props.stroke}
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};


Spinner.propTypes = {
  hide: PropTypes.bool,
  stroke: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
};

Spinner.defaultProps = {
  hide: false,
  stroke: '3'
};


export default Spinner;
