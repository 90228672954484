import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';


class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <Helmet>
        <title>Gumex</title>
        <meta name="description" content="Gumex, Műszaki gumilemezek, tömítések" />
        <meta name="keywords" content="filcek, gumi termékek, ipari műanyagok, hangszigetelők, ipari tömlők, szerelvények, tengelykapcsolók, tömítő anyagok" />
      </Helmet>
    );
  }
}


ScrollToTop.propTypes = {
  location: PropTypes.object.isRequired
};


export default withRouter(ScrollToTop);